import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#eceff1"
      d="M14.25 0H2.75C1.23 0 0 1.23 0 2.75v15.5C0 19.77 1.23 21 2.75 21h8.15c-.84-1.33-1.4-2.9-1.4-4.7v-4.01c0-.05 0-.1.01-.15.04-.85.44-1.63 1.07-2.14.28-.25.6-.43.95-.55l2.4-.82 2.85-.97c.07-.03.14-.05.22-.06V2.75C17 1.23 15.77 0 14.25 0z"
    />
    <g fill="#90a4ae">
      <path d="m13.93 8.63-2.4.82c-.35.12-.67.3-.95.55H4c-.55 0-1-.45-1-1s.45-1 1-1h9c.42 0 .78.26.93.63zM9.51 12.14c-.01.05-.01.1-.01.15v1.57c-.15.09-.32.14-.5.14H4c-.55 0-1-.45-1-1s.45-1 1-1h5c.19 0 .36.05.51.14zM8 6H4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z" />
    </g>
    <path
      fill="#4caf50"
      d="M17.75 24a.989.989 0 0 1-.392-.08c-.239-.102-5.858-2.549-5.858-7.617v-4.018a1 1 0 0 1 .678-.947l5.25-1.786c.209-.071.436-.071.645 0l5.25 1.786c.405.139.677.519.677.948v4.018c0 5.067-5.619 7.515-5.858 7.617a1.006 1.006 0 0 1-.392.079z"
    />
    <path
      fill="#fff"
      d="M17.5 19.5a.995.995 0 0 1-.707-.293l-2-2a.999.999 0 1 1 1.414-1.414l1.185 1.185L19.7 13.9a.999.999 0 1 1 1.599 1.2l-3 4a.996.996 0 0 1-.799.4z"
    />
  </svg>
);
export default SvgComponent;
