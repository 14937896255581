import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { PrescriptionDocumentMetaData } from "@udok/lib/api/models";
import Icons from "@udok/lib/components/Icon";
import Illustrations from "@udok/lib/components/Illustrations";
import { getPrescriptionMetadata } from "ducks/prescription";
import { AppDispatch } from "ducks/state";
import { Toolbar, Typography, LinearProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { format } from "@udok/lib/internal/util";
import clsx from "clsx";
import moment from "moment";
moment.locale("pt-br");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      maxWidth: 1250,
      padding: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        padding: 0,
        paddingTop: theme.spacing(1),
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(1),
    },
    dataContainer: {
      width: "100%",
      display: "flex",
      overflow: "hidden",
      gap: theme.spacing(1),
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      color: theme.palette.success.main,
    },
    illustration: {
      width: 120,
      height: "auto",
      [theme.breakpoints.down("lg")]: {
        width: 100,
      },
      [theme.breakpoints.down("md")]: {
        width: 80,
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

const usePrescriptionMetadata = (guid: string, verification: string) => {
  const [loading, setLoading] = useState(true);
  const [metadata, setMetadata] = useState<PrescriptionDocumentMetaData>();
  const dispatch: AppDispatch = useDispatch();

  const getMetadata = useCallback(() => {
    dispatch(getPrescriptionMetadata(guid, verification))
      .then(setMetadata)
      .finally(() => setLoading(false));
  }, [guid, verification, dispatch]);

  useEffect(() => {
    getMetadata();
  }, [getMetadata]);

  return [loading, metadata] as [typeof loading, typeof metadata];
};

type SignatureStampProps = {
  guid: string;
  verification: string;
};

export const SignatureStamp = (props: SignatureStampProps) => {
  const { guid, verification } = props;
  const [loading, metadata] = usePrescriptionMetadata(guid, verification);
  const classes = useStyles();
  const signedAt = moment(metadata?.signedAt ?? "").format(format.DAYMONYEAH);

  return (
    <Toolbar className={clsx(classes.toolbar)}>
      {loading ? <LinearProgress /> : null}
      {metadata ? (
        <div className={clsx(classes.dataContainer)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={classes.title}>
              <Icons.CheckBoxRounded />
              <Typography variant="subtitle1" color="textPrimary">
                <b>Informaçoes da Assinatura Digital:</b>
              </Typography>
            </div>

            <SignatureData title="Documento" label={`${metadata.name}`} />
            <SignatureData
              title="Assinado por"
              label={`${metadata.doctorName}`}
            />
            <SignatureData
              title={metadata.documentType.toUpperCase()}
              label={`${
                metadata.documentNumber
              } ${metadata.region.toUpperCase()}`}
            />
            <SignatureData title="Data da assinatura" label={signedAt} />
            <SignatureData
              title="Endereço"
              label={`${[
                metadata?.address?.neighborhood,
                metadata?.address?.street,
                metadata?.address?.city,
              ]
                .filter((a) => !!a)
                .join(" ")}
            ${metadata?.address?.cep ? " - " + metadata?.address?.cep : ""}
            ${
              metadata?.address?.cnes
                ? ", CNES: " + metadata?.address?.cnes
                : ""
            }`}
            />
            <SignatureData title="Hash" label={`${metadata.hash}`} />
          </div>
          <div>
            <Illustrations.Insurance className={classes.illustration} />
          </div>
        </div>
      ) : null}
    </Toolbar>
  );
};

const SignatureData = ({ title, label }: { title: string; label: string }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      <b>{`${title}:`}</b>&nbsp;{`${label}`}
    </Typography>
  );
};
