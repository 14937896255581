import React from "react";
import { Route, Switch } from "react-router";
import { OTPRedirect } from "screens/redirect/otpRedirect";

export default () => {
  return (
    <Switch>
      <Route exact path="/redirect" component={OTPRedirect} />
    </Switch>
  );
};
