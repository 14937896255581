import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";

import {
  SignTypeUdok,
  TemplateModel,
  SignTypeBirdID,
  CertificateModel,
  PrescriptionModel,
  CredentialsBirdID,
  TemplateModelFilter,
  CID10,
  MedicationAutocomplete,
  PrescriptionDocument,
  TextEntry,
  Medicine,
  PrescriptionDocumentMetaData,
} from "@udok/lib/api/models";
import { makeItemFetch } from "@udok/lib/api/axios";

export const createPrescription = async (
  token: string,
  prescription: PrescriptionModel
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/prescriptions`, prescription, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.item as PrescriptionModel;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre1");
      return Promise.reject(new Error(err));
    });
};

export const fetchPrescriptions = async (
  token: string,
  filter?: { includeReadonly?: boolean }
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/prescriptions${toQueryString({
        ...filter,
        orderByDate: "desc",
      })}`,
      {
        headers: { Authorization: token },
      }
    )
    .then((r) => {
      return r.data.data.items;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre2");
      return Promise.reject(err);
    });
};

export const fetchPrescription = async (token: string, presID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/prescriptions/${presID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as PrescriptionModel;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre3");
      return Promise.reject(err);
    });
};

export const fetchPrescriptionDocuments = async (
  token: string,
  presID: string
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/prescription-documents?presID=${presID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.items;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre4");
      return Promise.reject(err);
    });
};

export const authenticateBirdID = async (
  token: string,
  props: CredentialsBirdID
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/signature-provider/birdid/accesstoken`,
      props,
      {
        headers: { Authorization: token },
      }
    )
    .then((r) => {
      return r.data.data.item as CredentialsBirdID;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre5");
      return Promise.reject(err);
    });
};

export const createSign = async (
  token: string,
  props: SignTypeBirdID | SignTypeUdok
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/sign-documents/prescriptions`,
      props,
      {
        headers: { Authorization: token },
      }
    )
    .then((r) => {
      return r.data.data.item;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre6");
      return Promise.reject(new Error(err));
    });
};

export const fetchPrescriptionShare = async (token: string, prdoID: string) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/prescription-share`,
      { prdoID: prdoID },
      {
        headers: { Authorization: token },
      }
    )
    .then((r) => {
      const { guid, verification } = r.data.data.item;
      const url = `${process.env.REACT_APP_AUTH_PATH}/prescription-share/${guid}/${verification}`;
      return url;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre7");
      return Promise.reject(err);
    });
};

export const uploadCertificate = async (token: string, data: any) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/certificates`, data, {
      headers: {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => {
      return r.data.data.item as CertificateModel;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre10");
      return Promise.reject(err);
    });
};

export const fetchCertificates = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/certificates`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.items as CertificateModel[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre8");
      return Promise.reject(err);
    });
};

const makeCanceableModelsFetch = () => {
  let cancel: CancelTokenSource | undefined;

  return async (token: string, filter: TemplateModelFilter) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    return axios
      .get(
        `${
          process.env.REACT_APP_API_PATH
        }/prescriptions-template${toQueryString(filter)}`,
        {
          cancelToken: cancel.token,
          headers: { Authorization: token },
        }
      )
      .then((r) => {
        return r.data.data.items as TemplateModel[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as TemplateModel[];
        }
        const err = deriveErrorMessage(e, "pre9");
        return Promise.reject(new Error(err));
      });
  };
};

export const fetchModelsByFilter = makeCanceableModelsFetch();

export const createModels = async (
  token: string,
  prescription: TemplateModel
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/prescriptions-template`,
      prescription,
      {
        headers: { Authorization: token },
      }
    )
    .then((r) => {
      return r.data.data.item as TemplateModel;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre10");
      return Promise.reject(new Error(err));
    });
};

const makeCanceableCID10Fetch = () => {
  let cancel: CancelTokenSource | undefined;

  return async (token: string, searchTerm: string) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH}/cid10-listings${toQueryString({
          search: searchTerm,
          limit: 100,
        })}`,
        {
          cancelToken: cancel.token,
          headers: {
            Authorization: token,
          },
        }
      )
      .then((r) => {
        return r.data.data.items as CID10[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as CID10[];
        }
        const err = deriveErrorMessage(e, "pre11");
        return Promise.reject(new Error(err));
      });
  };
};

export const fetchAllCID10 = makeCanceableCID10Fetch();

export const fetchRecentCID10 = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/cid10-usages`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as CID10[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre13");
      return Promise.reject(new Error(err));
    });
};

export const registerCID10Usage = async (token: string, ciliID: string) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/cid10-usages`,
      { ciliID },
      {
        headers: { Authorization: token },
      }
    )
    .catch(() => console.warn);
};

export const createPrescriptionShareUrl = async (
  token: string,
  prdoID: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/prescription-share`,
      { prdoID: prdoID },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      const { guid, verification } = r.data.data.item;
      const shareUrl = `${process.env.REACT_APP_AUTH_PATH}/prescription-share/${guid}/${verification}`;
      const patientUrl = `${process.env.REACT_APP_PATIENT_WEB_APPLICATION_URL}/p/${guid}/${verification}`;
      return {
        shareUrl,
        patientUrl,
      };
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre12");
      return Promise.reject(new Error(err));
    });
};

export type PrescriptionEmailForm = {
  presID: string;
  recipientEmail: string;
};
export async function createPrescriptionEmail(
  apiToken: string,
  prescription: PrescriptionEmailForm
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/mailer-prescriptions`,
      prescription,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre13");
      return Promise.reject(new Error(err));
    });
}

export type FilterMedication = {
  patiID?: string;
  patientName?: string;
  drugID?: string;
  drugName?: string;
};

const makeCanceableMedicationFetch = () => {
  let cancel: CancelTokenSource | undefined;

  return async (token: string, filter?: FilterMedication) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const q = filter ? toQueryString(filter) : "";
    return axios
      .get(
        `${process.env.REACT_APP_API_PATH}/autocomplete/prescription-medication${q}`,
        {
          cancelToken: cancel.token,
          headers: {
            Authorization: token,
          },
        }
      )
      .then((r) => {
        return r.data.data.items as MedicationAutocomplete[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as MedicationAutocomplete[];
        }
        const err = deriveErrorMessage(e, "pre14");
        return Promise.reject(new Error(err));
      });
  };
};

export const fetchPrescriptionMedication = makeCanceableMedicationFetch();

export const deletePrescriptionModel = (apiToken: string, prmoID: string) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/prescriptions-template/${prmoID}`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as TemplateModel;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre15");
      return Promise.reject(new Error(err));
    });
};

export async function prescriptionsPhoneNotification(
  apiToken: string,
  presID: string,
  recipientPhone: string
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/notifications/phone-prescriptions`,
      { presID, recipientPhone },
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre16");
      return Promise.reject(new Error(err));
    });
}

export async function createUnsignedPrescription(
  apiToken: string,
  data: { presID: string }
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/unsigned-documents/prescriptions`,
      data,
      { headers: { Authorization: apiToken } }
    )
    .then((r) => {
      return r.data.data.items as PrescriptionDocument[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre17");
      return Promise.reject(new Error(err));
    });
}

export const updatePrescriptionModel = (
  apiToken: string,
  prmoID: string,
  data: { name: string; entries: Array<TextEntry> | Array<Medicine> }
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/prescriptions-template/${prmoID}`,
      data,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as TemplateModel;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pre18");
      return Promise.reject(new Error(err));
    });
};

export const fetchPrescriptionMetadata = async (
  guid: string,
  verification: string
) =>
  makeItemFetch<PrescriptionDocumentMetaData>({
    url: `/prescription-share/${guid}/${verification}/metadata`,
    errLabel: "PSMD1",
  });
