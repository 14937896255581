import { getToken, UNAUTHORIZED } from "./auth";
import { createSelector } from "reselect";
import { hen, Hen } from "@udok/lib/internal/store";
import { RootState, AppThunk } from "ducks/state";
import { newNotification } from "./notification";
import { setCookie } from "@udok/lib/internal/cookie";

import {
  SignTypeUdok,
  TemplateModel,
  SignTypeBirdID,
  CertificateModel,
  PrescriptionModel,
  CredentialsBirdID,
  PrescriptionDocument,
  CreateCertificateModel,
  SignerProvider,
  CID10,
  PrescriptionDocumentMetaData,
} from "@udok/lib/api/models";

import {
  createSign,
  fetchPrescription,
  fetchPrescriptions,
  createPrescription,
  authenticateBirdID,
  fetchPrescriptionShare,
  fetchPrescriptionDocuments,
  uploadCertificate,
  fetchAllCID10,
  fetchRecentCID10,
  registerCID10Usage,
  createPrescriptionShareUrl,
  fetchPrescriptionMetadata,
} from "@udok/lib/api/prescription";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export const BIRDID_COOKIENAME = `token${process.env.REACT_APP_APPLICATION_BIRDID}`;
export const VIDAAS_COOKIENAME = `tokenvidaas`;

export type InitialState = {
  certificateByID: { [certID: string]: CertificateModel };
  precriptionModels: Array<TemplateModel>;
  prescriptionsByID: {
    [presID: string]: PrescriptionModel;
  };
  prescriptionDocumentsByPresID: { [presID: string]: PrescriptionDocument[] };
  cid10ListingSearch: CID10[];
  cid10ListingRecent: CID10[];
};

const initialState: InitialState = {
  certificateByID: {},
  prescriptionsByID: {},
  precriptionModels: [],
  prescriptionDocumentsByPresID: {},
  cid10ListingSearch: [],
  cid10ListingRecent: [],
};

// Reducers
class PrescriptionSlice extends Hen<InitialState> {
  loadedPrescription(v: PrescriptionModel) {
    this.state.prescriptionsByID[v.presID] = v;
  }

  loadedPrescriptions(v: Array<PrescriptionModel>) {
    v.forEach((p: PrescriptionModel) => {
      this.state.prescriptionsByID[p.presID] = p;
    });
  }

  loadedPrescriptionDocuments(presID: string, v: Array<PrescriptionDocument>) {
    this.state.prescriptionDocumentsByPresID[presID] = v;
  }

  loadCertificate(c: CertificateModel) {
    this.state.certificateByID[c.certID] = c;
  }

  loadCertificates(c: CertificateModel[]) {
    c.forEach((cert) => {
      this.state.certificateByID[cert.certID] = cert;
    });
  }

  loadCID10Search(cids: CID10[]) {
    this.state.cid10ListingSearch = cids;
  }
  loadCID10Recent(cids: CID10[]) {
    this.state.cid10ListingRecent = cids;
  }
  clearSearchCID10() {
    this.state.cid10ListingSearch = [];
  }
}

export const [Reducer, actions] = hen(new PrescriptionSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.prescription;

export const prescriptionListView = createSelector(
  [mainSelector, getToken],
  (state, auth) => {
    return {
      list: Object.keys(state.prescriptionsByID)
        .map((presID) => state.prescriptionsByID[presID])
        .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
    };
  }
);

export const getPrescriptionInfoByID = (
  state: RootState,
  props: { presID: string }
) =>
  createSelector([mainSelector], (state) => {
    return {
      prescription: state.prescriptionsByID[props.presID],
      prescriptionDocuments:
        state.prescriptionDocumentsByPresID[props.presID] ?? [],
      models: state.precriptionModels ?? [],
    };
  });

export const getPrescriptionCertificate = (state: RootState) =>
  createSelector([mainSelector], (state) => {
    return {
      certificate:
        Object.keys(state.certificateByID)
          .map((id) => state.certificateByID[id])
          .sort((a, b) => (a.createdAt! > b.createdAt! ? -1 : 1))[0] ?? {},
    };
  });

export const getPrescriptionModels = (state: RootState) =>
  createSelector([mainSelector], (state) => {
    return {
      models: state.precriptionModels,
    };
  });

export const getListCertificateView = createSelector(mainSelector, (state) => {
  return {
    list: Object.keys(state.certificateByID)
      .map((id) => state.certificateByID[id])
      .sort((a, b) => {
        const date1 = moment(b.invalidAt).utc();
        const date2 = moment(a.invalidAt).utc();
        return date1.diff(date2, "minutes");
      }),
  };
});

export const getCID10SearchView = createSelector(mainSelector, (state) => {
  return {
    listSearch: state.cid10ListingSearch ?? [],
    listRecent: state.cid10ListingRecent ?? [],
  };
});

// Actions
export function addPrescription(
  spec: PrescriptionModel
): AppThunk<Promise<PrescriptionModel>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createPrescription(apiToken, spec)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        dispatch(loadPrescription(r.presID));
        return Promise.resolve(r as PrescriptionModel);
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        return Promise.reject(e);
      });
  };
}

export function loadPrescriptions(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescriptions(apiToken)
      .then((r) => {
        dispatch(actions.loadedPrescriptions(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadPrescription(presID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescription(apiToken, presID)
      .then(async (r) => {
        dispatch(actions.loadedPrescription(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadPrescriptionDocuments(
  presID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescriptionDocuments(apiToken, presID)
      .then((r) => {
        dispatch(actions.loadedPrescriptionDocuments(presID, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadPrescriptionShareLink(
  prdoID: string
): AppThunk<Promise<void | string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescriptionShare(apiToken, prdoID)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function createCertificate(
  fileInfo: CreateCertificateModel
): AppThunk<Promise<void>> {
  const data = new FormData();
  data.append("password", fileInfo.password as any);
  data.append("file", fileInfo.file);
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return uploadCertificate(apiToken, data)
      .then((c) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Certificado enviado com sucesso!",
          })
        );
        dispatch(actions.loadCertificate(c));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function searchCID10(searchTerm: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAllCID10(apiToken, searchTerm)
      .then((r) => {
        if (r) {
          dispatch(actions.loadCID10Search(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadRecentCID10(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchRecentCID10(apiToken)
      .then((r) => {
        dispatch(actions.loadCID10Recent(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function addCID10Usage(ciliID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return registerCID10Usage(apiToken, ciliID)
      .then(() => {})
      .catch(() => {});
  };
}

export function clearCID10(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(actions.clearSearchCID10());
  };
}

// Signer Actions
export function authenticatorBirdID(
  props: CredentialsBirdID,
  date: Date
): AppThunk<Promise<CredentialsBirdID>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return authenticateBirdID(apiToken, props)
      .then((r) => {
        if (r?.accessToken) {
          setCookie(BIRDID_COOKIENAME, r.accessToken, date);
        }
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        return Promise.resolve(r as CredentialsBirdID);
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        return Promise.reject(e);
      });
  };
}

export function signPrescription(
  props: SignTypeBirdID | SignTypeUdok
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    if (
      !(props as SignTypeUdok).certID &&
      props.provider === SignerProvider.Udok
    ) {
      const err = "Certificado não identificado. Tente novamente.";
      dispatch(
        newNotification("general", {
          status: "error",
          message: err,
        })
      );
      return Promise.reject(err);
    }
    return createSign(apiToken, props)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        dispatch(loadPrescription(props.presID));
        dispatch(loadPrescriptionDocuments(props.presID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        return Promise.reject(e);
      });
  };
}

export function createShareUrlDocument(
  prdoID: string
): AppThunk<Promise<{ shareUrl: string; patientUrl: string }>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createPrescriptionShareUrl(apiToken, prdoID).catch((e) => {
      dispatch(
        newNotification("general", {
          status: "error",
          message: e.message,
        })
      );
      return Promise.reject(e);
    });
  };
}

export function getPrescriptionMetadata(
  guid: string,
  verification: string
): AppThunk<Promise<PrescriptionDocumentMetaData>> {
  return async (dispatch) => {
    return fetchPrescriptionMetadata(guid, verification).catch((e) => {
      dispatch(
        newNotification("general", {
          status: "error",
          message: e.message,
        })
      );
      return Promise.reject(e);
    });
  };
}
