import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icons from "@udok/lib/components/Icon";

const defaultSize = 40;
const defaultInnerSize = 30;
const defaultOffset = 5;

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    position: "relative",
    width: (p) => p.size,
    height: (p) => p.size,
  },
  icon: {
    width: (p) =>
      p.size ? (defaultInnerSize * p.size) / defaultSize : defaultInnerSize,
    height: (p) =>
      p.size ? (defaultInnerSize * p.size) / defaultSize : defaultInnerSize,
    position: "absolute",
    top: (p) =>
      p.size ? (defaultOffset * p.size) / defaultSize : defaultOffset,
    left: (p) =>
      p.size ? (defaultOffset * p.size) / defaultSize : defaultOffset,
  },
}));

type Props = {
  size?: number;
};

const Search = (p: Props) => {
  const classes = useStyles(p);
  return (
    <div className={classes.root}>
      <CircularProgress size={p.size} />
      <Icons.UdokU className={classes.icon} />
    </div>
  );
};

export default Search;
