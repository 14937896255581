import * as Icons from "@material-ui/icons";
import SpeechBubble from "./SpeechBubble";
import CommentFlash from "./CommentFlash";
import CommentFlashOutline from "./CommentFlashOutline";
import CreditCardGeneric from "./CreditCardGeneric";
import Eraser from "./Eraser";
import User from "./User";
import Template from "./Template";
import Prescription from "./Prescription";
import Plus from "./Plus";
import Menu from "./Menu";
import Eye from "./Eye";
import Examination from "./Examination";
import Dashboard from "./Dashboard";
import Config from "./Config";
import Chat from "./Chat";
import Calendar from "./Calendar";
import ArrowRight from "./ArrowRight";
import ArrowDown from "./ArrowDown";
import StepIcon from "./StepIcon";
import PinHouse from "./PinHouse";
import VideoCall from "./VideoCall";
import ChatCircular from "./ChatCircular";
import AnnouncementCircular from "./AnnouncementCircular";
import PinMap from "./PinMap";
import FillForm from "./FillForm";
import PIX from "./PIX";
import Barcode from "./Barcode";
import PDFOutlined from "./PDFOutlined";
import FindInFileOutlined from "./FindInFileOutlined";
import FormOutlined from "./FormOutlined";
import AccountFormOutlined from "./AccountFormOutlined";
import FileCopyLinkOutlined from "./FileCopyLinkOutlined";
import ArrowLeftCircular from "./ArrowLeftCircular";
import PositionSettings from "./PositionSettings";
import PositionSettingsSimple from "./PositionSettingsSimple";
import ResizeHeight from "./ResizeHeight";
import ResizeWidth from "./ResizeWidth";
import Percent from "./Percent";
import BRL from "./BRL";
import Stethoscope from "./Stethoscope";
import ScrollDown from "./ScrollDown";
import ExpandOutlined from "./ExpandOutlined";
import GoBackOutlined from "./GoBackOutlined";
import DollarSingle from "./DollarSingle";
import Bank from "./Bank";
import PillsOutlined from "./PillsOutlined";
import Drug from "./Drug";
import PanelRetract from "./PanelRetract";
import PanelExpand from "./PanelExpand";
import MinimizeOutlined from "./MinimizeOutlined";
import ReplyRightOutlined from "./ReplyRightOutlined";
import FormOtlined from "./FormOutlined";
import PhoneSupport from "./PhoneSupport";
import House from "./House";
import CircleEditOutline from "./CircleEditOutline";
import Excel from "./Excel";
import CardAccountPlusOutline from "./CardAccountPlusOutline";
import ResizeLarger from "./ResizeLarger";
import ResizeSmaller from "./ResizeSmaller";
import AddPhone from "./AddPhone";
import Doctor from "./Doctor";
import PostageStamp from "./PostageStamp";
import AvatarEmpty from "./AvatarEmpty";
import Door from "./Door";
import DoorExit from "./DoorExit";
import AssessmentOutlined from "./AssessmentOutlined";
import UdokU from "./UdokU";

export default {
  ...Icons,
  SpeechBubble,
  CommentFlash,
  CommentFlashOutline,
  CreditCardGeneric,
  Eraser,
  User,
  Template,
  Prescription,
  Plus,
  Menu,
  Eye,
  Examination,
  Dashboard,
  Config,
  Chat,
  Calendar,
  ArrowRight,
  ArrowDown,
  StepIcon,
  PinHouse,
  VideoCall,
  ChatCircular,
  AnnouncementCircular,
  PinMap,
  FillForm,
  PIX,
  Barcode,
  PDFOutlined,
  FindInFileOutlined,
  FormOutlined,
  AccountFormOutlined,
  FileCopyLinkOutlined,
  ArrowLeftCircular,
  PositionSettings,
  PositionSettingsSimple,
  ResizeHeight,
  ResizeWidth,
  Percent,
  BRL,
  Stethoscope,
  ScrollDown,
  ExpandOutlined,
  GoBackOutlined,
  DollarSingle,
  Bank,
  PillsOutlined,
  Drug,
  PanelRetract,
  PanelExpand,
  MinimizeOutlined,
  ReplyRightOutlined,
  FormOtlined,
  PhoneSupport,
  House,
  CircleEditOutline,
  Excel,
  CardAccountPlusOutline,
  ResizeLarger,
  ResizeSmaller,
  AddPhone,
  Doctor,
  PostageStamp,
  AvatarEmpty,
  Door,
  DoorExit,
  AssessmentOutlined,
  UdokU,
};
