import React from "react";
import axios from "axios";
import {
  Toolbar,
  Button,
  CircularProgress,
  Collapse,
  Link,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Logo from "@udok/lib/components/Illustrations/UdokLogoLarge";
import Icons from "@udok/lib/components/Icon";
import Illustrations from "@udok/lib/components/Illustrations";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "column",
      maxWidth: 1250,
      padding: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        padding: 0,
        paddingTop: theme.spacing(1),
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      padding: 0,
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 1, 0, 1),
      },
    },
    logo: {
      height: "7vh",
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        width: 125,
      },
    },
    errorRoot: {
      maxWidth: 648,
      padding: theme.spacing(3),
      margin: "auto",
      marginTop: theme.spacing(6),
    },
    errorContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    spaceTop: {
      marginTop: theme.spacing(1),
    },
    spaceBottom: {
      marginBottom: theme.spacing(2),
    },
  })
);

export type UdokDownloadProps = {
  url: string;
  type: string;
  classes?: {
    container?: string;
    toolbar?: string;
    logo?: string;
  };
  children?: React.ReactNode;
};

const UdokDownload = (props: UdokDownloadProps) => {
  const { url, type, classes: propsClasses, children } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, propsClasses?.container)}>
      <Toolbar className={clsx(classes.toolbar, propsClasses?.toolbar)}>
        <Logo className={clsx(classes.logo, propsClasses?.logo)} />
        <Button
          component="a"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          download
          variant="outlined"
          color="primary"
          startIcon={<Icons.GetAppRounded />}
        >
          <b>Download</b>
        </Button>
      </Toolbar>
      {children}
      <div style={{ flex: "1 0 100%" }}>
        {url ? (
          <object data={url} type={type} width="100%" height="100%">
            <div className={classes.errorRoot}>
              <RenderError url={url} />
            </div>
          </object>
        ) : (
          <div className={classes.errorRoot}>
            <div className={classes.errorContainer}>
              <div className={classes.spaceBottom}>
                <Illustrations.FileError height={128} width={128} />
              </div>
              <Typography gutterBottom variant="h6">
                Houve um erro ao carregar o documento.
              </Typography>
              <Typography variant="subtitle1">
                Não conseguimos encontrar esse arquivo.
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const RenderError = ({ url }: { url: string }) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const classes = useStyles();

  const getDateToUrl = React.useCallback((url: string) => {
    return axios.get(url);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getDateToUrl(url)
      .catch((e) => setError(e?.message))
      .finally(() => setLoading(false));
  }, [url, getDateToUrl]);

  const onClickError = React.useCallback(() => {
    setOpen((o) => !o);
  }, []);

  if (loading) {
    return (
      <div className={classes.errorContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.errorContainer}>
        <div className={classes.spaceBottom}>
          <Illustrations.FileError height={128} width={128} />
        </div>
        <Typography gutterBottom variant="h6">
          Houve um erro ao carregar o documento.
        </Typography>
        <Typography variant="subtitle1">
          Verifique o endereço e tente novamente
        </Typography>
        <Link component="button" variant="body2" onClick={onClickError}>
          Detalhes do erro
        </Link>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Alert className={classes.spaceTop} severity="error">
            {error}
          </Alert>
        </Collapse>
      </div>
    );
  }

  return (
    <div className={classes.errorContainer}>
      <div className={classes.spaceBottom}>
        <Illustrations.File height={128} width={128} />
      </div>
      <Typography gutterBottom variant="h6">
        Documento disponível para download
      </Typography>
      <Typography variant="subtitle1">
        Clique o botão abaixo para visualizá-lo
      </Typography>
      <Button
        component="a"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        download
        variant="outlined"
        color="primary"
        startIcon={<Icons.GetAppRounded />}
        className={classes.spaceTop}
      >
        <b>Download</b>
      </Button>
    </div>
  );
};

export default UdokDownload;
