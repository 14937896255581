import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        isolation: "isolate",
      }}
      viewBox="817.8 407.3 234.4 234.4"
      width="234.4pt"
      height="234.4pt"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M817.8 407.3h234.4v234.4H817.8z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M935 407.3c-64.7 0-117.2 52.4-117.2 117.2 0 64.8 52.5 117.2 117.2 117.2s117.2-52.5 117.2-117.2S999.7 407.3 935 407.3zm47.7 143.5c0 15.8-5 27.7-15.1 35.7-9 7.6-20.2 11.9-33.8 11.9-13.4 0-24.4-4.3-33.1-11.9-9.6-8.1-14.3-20-14.3-35.7v-81.4c0-7.6 7.9-7.6 7.9 0v81.5c0 10.5 4.3 22.2 11.3 28.3 6.7 5.7 11.3 10.8 27.7 10.8 15.5 0 25.8-6.8 31.9-12.7 6.1-5.7 9.6-15.7 9.6-26.5v-81.4c0-7.6 7.8-7.6 7.8 0v81.4h.1z"
          fillRule="evenodd"
          fill="#008893"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
